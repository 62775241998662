$(document).ready(function () {
	try {
		$("[data-fancybox]").fancybox({
			youtube: {
				controls: 0,
				showinfo: 0,
				autoplay: 1,
			},
			vimeo: {
				color: "f00",
			},
		});
		$("#link_popup_2").fancybox({}).trigger("click");
	} catch (error) {}

	//Library Base function
	$('[data-fancybox=""]').fancybox({
		smallBtn: true,
		toolbar: true,
		type: "html",
		clickSlide: "toggleControls",
	});

	if ($(window).width() < 1025) {
		$(".navbar-primary-menu").fadeIn(function () {
			$(".navbar-primary-menu").css({
				display: "flex",
			});
		});
		$(".search-wrapper").fadeIn(function () {
			$(".search-wrapper").css({
				display: "flex",
			});
		});
		$(".contact-wrapper").fadeIn(function () {
			$(".contact-wrapper").css({
				display: "flex",
			});
		});
		$(".account-wrapper").fadeIn(function () {
			$(".account-wrapper").css({
				display: "flex",
			});
		});
	}
	if ($(window).width() < 1025) {
		$(".drop-down .title em").on("click", function () {
			if ($(this).parents(".drop-down").hasClass("open-sub")) {
				$(".drop-down .nav-sub").slideUp();
				$(".drop-down").removeClass("open-sub");
			} else {
				$(".drop-down .nav-sub").slideUp();
				$(".drop-down").removeClass("open-sub");
				$(this).parent().next().slideDown();
				$(this).parents(".drop-down").addClass("open-sub");
			}
		});
	}
	setBackground();
	tabActive();
	swiperInit();
	toggleMobileMenu();
	//Declare NEW function Javascript
	backToTop();
	toggleShoppingCart();
	mappingMenu();
	mappingSearch();
	mappingProfile();
	mappingContact();
	toggleSupport();
	appednCommend();
	addClassHomeCustom();
	$.fancybox.defaults.parentEl = "form";
});
window.onscroll = function () {
	// scrollHeader();
	fixHeader();
};

function appednCommend() {
	if ($("body").hasClass("product-detail-page")) {
		// if ($(".commentpanel").length) {
		// 	$('.commentpanel').appendTo('.product-review-tab')
		// }
		if ($(".recent-product-wrap").length) {
			$(".recent-product-wrap").appendTo(".recently-view-product");
		}
	}
	if ($("body").hasClass("news-detail-page")) {
		if ($(".side-navigation").length) {
			$(".side-navigation").appendTo(".news-detail-zone-wrap");
		}
	}
	if ($("body").hasClass("homepage")) {
		if ($(".subscrible-form-wrapper").length) {
			$(".subscrible-form-wrapper-popup").append(
				$(".subscrible-form-wrapper")
			);
		}
	}
	if ($("body").hasClass("news-section-wrap")) {
		if ($(".zone-navigation").length) {
			if ($(".zone-navigation li:nth-child(2)").hasClass("active")) {
				$(".zone-navigation li:first-child").removeClass("active");
			}
			if ($(".zone-navigation li:nth-child(3)").hasClass("active")) {
				$(".zone-navigation li:first-child").removeClass("active");
			}
		}
	}
}

function sideNavigation() {
	$(".side-navigation-nav .title em").on("click", function () {
		if ($(this).parents("li").hasClass("active")) {
			$(".side-navigation-nav .drop-down").slideUp();
			$(".side-nav li").removeClass("active");
		} else {
			$(".side-navigation-nav .drop-down").slideUp();
			$(".side-nav li").removeClass("active");
			$(this).parent().next().slideDown();
			$(this).parents("li").addClass("active");
		}
	});
}

function toggleSupport() {
	$(".toggle-item > .title").click(function (e) {
		e.preventDefault();
		if (!$(this).parent().hasClass("active")) {
			$(".toggle-item article").slideUp();
			$(this).next().slideToggle();
			$(".toggle-item").removeClass("active");
			$(this).parent().addClass("active");
		} else {
			$(this).next().slideToggle();
			$(".toggle-item").removeClass("active");
		}
	});
}

function mappingProfile() {
	return new MappingListener({
		selector: ".account-wrapper",
		mobileWrapper: ".site-profile-wrap",
		mobileMethod: "appendTo",
		desktopWrapper: ".shopping-cart-toggle",
		desktopMethod: "insertBefore",
		breakpoint: 1025,
	}).watch();
}

function mappingContact() {
	return new MappingListener({
		selector: ".contact-wrapper",
		mobileWrapper: ".site-nav-bottom",
		mobileMethod: "appendTo",
		desktopWrapper: ".shopping-cart-toggle",
		desktopMethod: "insertBefore",
		breakpoint: 1025,
	}).watch();
}

function mappingSearch() {
	return new MappingListener({
		selector: ".search-wrapper",
		mobileWrapper: ".site-nav-bottom",
		mobileMethod: "appendTo",
		desktopWrapper: ".shopping-cart-toggle",
		desktopMethod: "insertBefore",
		breakpoint: 1025,
	}).watch();
}

function mappingMenu() {
	return new MappingListener({
		selector: ".navbar-primary-menu",
		mobileWrapper: ".site-primary-nav",
		mobileMethod: "appendTo",
		desktopWrapper: ".header-wrap-bottom",
		desktopMethod: "appendTo",
		breakpoint: 1025,
	}).watch();
}

function fixHeader() {
	if ($(window).width() > 1025) {
		if (
			document.body.scrollTop > 80 ||
			document.documentElement.scrollTop > 80
		) {
			$("header").addClass("minimize");
		} else {
			$("header").removeClass("minimize");
		}
	}
}

$(document).on("click", function (e) {
	if (
		$(".shopping-cart-toggle").has(e.target).length === 0 &&
		$(".shopping-cart-wrapper").has(e.target).length === 0
	) {
		$(".shopping-cart-wrapper").removeClass("open");
	}
	if ($(".suggestsearch").has(e.target).length === 0) {
		$(".suggestsearch").fadeOut();
	}
});

function toggleShoppingCart() {
	$(".shopping-cart-toggle").on("click", function (e) {
		$(".shopping-cart-wrapper").toggleClass("open");
		e.stopPropagation();
	});
}

function backToTop() {
	let btn = $(".back-to-top");
	btn.on("click", function (e) {
		e.preventDefault();
		$("html, body").animate(
			{
				scrollTop: 0,
			},
			"300"
		);
	});
}

function swiperInit() {
	var homerSwiper = new Swiper(".primary-banner .swiper-container", {
		// Optional parameters
		slidesPerView: 1,
		speed: 1205,
		autoplay: {
			delay: 3000,
		},
		lazy: {
			loadPrevNext: true,
		},
		pagination: {
			el: ".primary-banner  .swiper-pagination",
			type: "bullets",
			clickable: true,
		},
	});
	var homeOnlineShop = new Swiper(".onlineshop-swiper .swiper-container", {
		slidesPerView: 6,
		spaceBetween: 30,
		lazy: {
			loadPrevNext: true,
		},
		navigation: {
			nextEl: ".onlineshop-swiper .btn-next",
			prevEl: ".onlineshop-swiper .btn-prev",
		},
		breakpoints: {
			1024: {
				slidesPerView: 4,
				spaceBetween: 30,
			},
			400: {
				slidesPerView: 2,
				spaceBetween: 15,
			},
		},
	});
	var topBanner = new Swiper(".top-banner .swiper-container", {
		// Optional parameters
		speed: 1205,
		lazy: {
			loadPrevNext: true,
		},
		slidesPerView: 1,
		autoplay: {
			delay: 3000,
		},
	});

	$(".trinity-swiper .swiper-container").each(function (index, element) {
		var $this = $(this);
		$this.addClass("tri-instance-" + index);
		$this
			.parent()
			.find(".nav-arrow-prev")
			.addClass("btn-prev-" + index);
		$this
			.parent()
			.find(".nav-arrow-next")
			.addClass("btn-next-" + index);

		var swiper = new Swiper(".tri-instance-" + index, {
			speed: 1205,
			lazy: {
				loadPrevNext: true,
			},
			speed: 750,
			observer: true,
			spaceBetween: 30,
			observeParents: true,
			breakpointsInverse: true,
			breakpoints: {
				200: {
					slidesPerView: 1,
				},
				576: {
					slidesPerView: 2,
				},
				769: {
					slidesPerView: 3,
				},
				1200: {
					slidesPerView: 3,
				},
			},
			navigation: {
				nextEl: ".btn-next-" + index,
				prevEl: ".btn-prev-" + index,
			},
		});
	});
	$(".single-swiper .swiper-container").each(function (index, element) {
		var $this = $(this);
		$this.addClass("single-instance-" + index);
		$this
			.parent()
			.find(".nav-arrow-prev")
			.addClass("btn-prev-" + index);
		$this
			.parent()
			.find(".nav-arrow-next")
			.addClass("btn-next-" + index);
		$this
			.parent()
			.find(".swiper-pagination")
			.addClass("pagination-instance-" + index);

		var swiper = new Swiper(".single-instance-" + index, {
			speed: 1205,
			lazy: {
				loadPrevNext: true,
			},
			speed: 750,
			observer: true,
			spaceBetween: 30,
			observeParents: true,
			slidesPerView: 1,
			navigation: {
				nextEl: ".btn-next-" + index,
				prevEl: ".btn-prev-" + index,
			},
			pagination: {
				el: ".pagination-instance-" + index,
				type: "bullets",
				clickable: true,
			},
		});
	});
	$(".four-swiper .swiper-container").each(function (index, element) {
		var $this = $(this);
		$this.addClass("instance-" + index);
		$this
			.parent()
			.find(".nav-arrow-prev")
			.addClass("btn-prev-" + index);
		$this
			.parent()
			.find(".nav-arrow-next")
			.addClass("btn-next-" + index);

		var swiper = new Swiper(".instance-" + index, {
			speed: 1205,
			lazy: {
				loadPrevNext: true,
			},
			speed: 750,
			observer: true,
			spaceBetween: 30,
			observeParents: true,
			breakpointsInverse: true,
			breakpoints: {
				200: {
					slidesPerView: 1,
				},
				400: {
					slidesPerView: 2,
				},
				576: {
					slidesPerView: 2,
				},
				769: {
					slidesPerView: 3,
				},
				1200: {
					slidesPerView: 4,
				},
			},
			navigation: {
				nextEl: ".btn-next-" + index,
				prevEl: ".btn-prev-" + index,
			},
		});
	});
}

//Toggle mobile menu

function toggleMobileMenu() {
	var $hamburger = $(".hamburger");
	$(".main-menu-toggle").on("click", function () {
		$(".site-header-mobile").toggleClass("open-nav");
		$hamburger.toggleClass("is-active");
	});
	$(".dropdown .title em").on("click", function () {
		if ($(this).parents(".drop-down").hasClass("open-sub")) {
			console.log(12310);
			$(".dropdown .nav-sub").slideUp();
			$(".drop-down").removeClass("open-sub");
		} else {
			$(".dropdown .nav-sub").slideUp();
			$(".dropdown").removeClass("open-sub");
			$(this).parent().next().slideDown();
			$(this).parents(".dropdown").addClass("open-sub");
			console.log(9999);
		}
	});
}

//Tab active

function tabActive() {
	$(".tab-navigation li a").on("click", function () {
		$(this).parents(".tab-navigation").find("li").removeClass("active");
		$(this).parents("li").addClass("active");

		var display = $(this).attr("data-type");
		$(".tab-item").removeClass("active");
		$("#" + display).addClass("active");
	});

	// $('.about-personnel-committee figure .watch-more-about-us').on('click', function () {
	// 	if ($(this).parent().hasClass('active')) {
	// 		$(this).parent().removeClass('active')
	// 	} else {
	// 		$('.about-personnel-committee figure article').removeClass('active')
	// 		$(this).parent().addClass('active')
	// 	}
	// })
}
// Side
function setBackground() {
	$("[setBackground]").each(function () {
		var background = $(this).attr("setBackground");
		$(this).css({
			"background-image": "url(" + background + ")",
			"background-size": "cover",
			"background-position": "center center",
		});
	});
	$("[setBackgroundRepeat]").each(function () {
		var background = $(this).attr("setBackgroundRepeat");
		$(this).css({
			"background-image": "url(" + background + ")",
			"background-repeat": "repeat",
		});
	});
}

function addClassHomeCustom() {
	const total = $(".onlineshop-swiper .swiper-slide").length;

	if (total < 6) {
		$(".onlineshop-swiper .swiper-wrapper").addClass("justify-center");
	}
}
